textarea{
  resize: none;
}
.ml-12{
  margin-left: 12px !important;
}
.mt-100{
  margin-top: 100px;
}
.App {
  text-align: center;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.side-menu ul{
  margin: 0;
  padding: 0;
  text-align: left;
  list-style-type: none;
}
.side-menu ul li{
  padding: 12px 0 12px 12px;
}
.side-menu ul li a{
  display: flex;
  font-size: 18px;
  font-weight: bold;
  align-items: center;
  text-decoration: none;
}
.side-menu ul li a svg{
  margin-right: 8px;
}
.form-box{
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: flex-start;
}
.form-box label{
  text-align: left;
  font-weight: bold;
}
.mt-80{
  margin-top: 80px;
}
.time .react-time-picker__wrapper{
  border: none;
}
.time .react-time-picker__inputGroup{
  text-align: left;
}
.date .react-datepicker__calendar-icon{
  position: absolute;
  right: 0;
}
.pd-r{
  padding: 24px 48px 48px 48px;
}
.pd-r a{
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  display: flex;
  align-items: center;
}
.pd-r a:hover{
  color: #000000;
}
.mr-6{
  margin-right: 6px;
}
.mb-48{
  margin-bottom: 48px;
}
.company-detail{
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d71ef7;
}
.company-detail img{
  width: 100px;
  border-radius: 50%;
}
.login-form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.dropdown-list{
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.dropdown-list li{
  padding: 8px 12px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ml-4{
  margin-left: 4px;
}
.navbar-dark .navbar-toggler{
  border: none;
  color: #fff;
}
.page-not-found{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.event-form-container{
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
}
.btn:focus{
  box-shadow: none;
}
.form-control:focus,
.form-select:focus{
  box-shadow: none;
}
/* .rich-text .jodit-ui-group_group_media,
.rich-text .jodit-ui-group_group_state,
.rich-text .jodit-ui-group_group_insert,
.rich-text .jodit-ui-group_group_indent,
.rich-text .jodit-ui-group_group_color,
.rich-text .jodit-ui-group_group_other,
.rich-text .jodit-ui-group_group_history,
.rich-text .jodit-status-bar{
  display: none;
} */
.rich-text .jodit-status-bar{
  display: none;
}
.rich-text .jodit-workplace,
.rich-text .jodit-wysiwyg{
  min-height: 132px !important;
}
.btn-hide-show{
  position: absolute;
  right: 8px;
  top: 12%;
  background: none;
  border: none;
}
.event-image{
  width: 100%;
  text-align: center;
}
.event-image img{
  width: 80%;
  border-radius: 4px;
  margin-top: 8px;
}
.new-size div{
  width: 16%;
}
.new-size img{
  width: 100%;
}
.btn-cross{
  background: transparent;
  border: none;
  color: red;
  position: absolute;
  top: 4px;
  right: 0px;
  z-index: 1;
  font-weight: bold;
}
.img-loader{
  height: 44px;
}
.btn:focus, 
.btn-close:focus{
  box-shadow: none;
}
.primary-button{
  padding: 7px 8px;
  color: #d71ef7 !important;
  border-radius: 4px !important;
  font-weight: bold !important;
  border-color: #d71ef7 !important;
}
.primary-button:hover{
  background-color: #fff;
}
.active-link{
  color: #fff;
  border-radius: 3px;
  background-color: #d71ef7;
}
.active-link:hover{
  color: #fff;
}
.inactive-link{
  color: #d71ef7;
  background-color: #fff;
}
.inactive-link:hover{
  color: #d71ef7;
}
.page-link:focus{
  box-shadow: none;
}
.t-body tr{
  height: 60px;
}
.t-body tr td,
.t-body tr th{
  vertical-align: middle;
}
.page-link{
  color: #d71ef7;
  font-weight: bold;
}
.page-link:hover{
  color: #d71ef7;
}
.page-link:active{
  color: #d71ef7;
}
.bg-custom{
  background-color: #d71ef7;
}
.primary-button-2{
  width: 100%;
  border: none;
  height: 40px;
  color: #fff;
  font-weight: bold;
  background-color: #d71ef7;
}
.primary-button-2:hover{
  color: #fff;
  background-color: #d71ef7;
}
.primary-button-2 img{
  width: 28px !important;
}
.btn-disabled{
  background-color: rgba(0, 0, 0, 0.3);
}
.btn-disabled:hover{
  background-color: rgba(0, 0, 0, 0.3);
}
.w-78{
  width: 78px;
}
.w-p-48{
  width: 48%;
}
.mr-8{
  margin-right: 8px;
}
.modal-detail h1{
  color: #000;
  font-weight: bold;
}
.right-border{
  border-right: 2px solid #0c97ed;
}
.graph-box{
  border-bottom: 2px solid #0c97ed;
}
.graph-box h3{
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin: 12px 0;
}
.invoice h5{
  color: #0c97ed;
  border-bottom: 1px solid #0c97ed;
  width: fit-content;
  margin: 0 0 4px 0;
  padding: 4px 0 6px 0;
  font-weight: bold;
}
.invoice h2{
  color: #0c97ed;
  border-bottom: 1px solid #0c97ed;
  width: fit-content;
  margin: 0;
  text-align: right;
  margin-bottom: 12px;
  padding-bottom: 8px;
  font-weight: bold;
}
.invoice-detail p{
  margin: 0px;
  font-size: 13px;
}
.invoice-detail p span:first-child{
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
}
.invoice-detail p span:last-child{
  color: rgba(0, 0, 0, 0.8);
}
.receipt{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}
.invoice-detail p{
  margin: 0px;
  font-size: 13px;
}
.invoice-detail p span:first-child{
  color: rgba(0, 0, 0, 0.8);
  font-weight: bold;
}
.invoice-detail p span:last-child{
  color: rgba(0, 0, 0, 0.8);
}
.order-name span:first-child{
  padding-right: 31px;
}
.order-id span:first-child{
  padding-right: 24px;
}
.payment-id span:first-child{
  padding-right: 5px;
}
.order-status span:first-child{
  padding-right: 39px;
}
.order-status span:last-child{
  color: #fff !important;
}
.logo-in-invoice{
  width: 57px;
}
.logo-in-invoice img{
  width: 100%
}
.order-table{
  margin-top: 16px;
}
.tr-repeat svg, 
.tr-static svg, 
.tr-total svg{
  margin-bottom: 2px;
}
.subtotal, .gst{
  background-color: rgba(0, 0, 0, 0.15) !important;
}
.tr-total{
  background-color: #0c97ed;
}
.tr-total .total{
  color: #fff;
  font-weight: bold;
}
.user-detail p{
  margin: 4px 0 0px 0;
}
.user-detail p span:first-child{
  font-weight: bold;
}
.btn:focus{
  box-shadow: none;
}
.w-84{
  width: 84px;
}
.ml-8{
  margin-left: 8px;
}
.error{
  margin: 0;
  color: red;
  bottom: -16px;
  font-size: 12px;
  font-weight: bold;
  position: absolute;
}
.time-error{
  bottom: 0px;
}
.btn-duplicate{
  position: absolute;
  top: 72px;
  right: 20px;
}
.btn-duplicate .form-check-input:focus{
  box-shadow: none;
}
.date-filter{
  width: 180px;
  margin-left: 12px;
}
.date-aria{
  display: flex;
  align-items: center;
}
.main-filter{
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.top-wrapper{
  padding-top: 104px;
}
.event-detail-upper img{
  width: 100%;
  border-radius: 6px;
}
.event-description-box{
  margin: 24px 0 48px 0;
}
.event-description-box h4{
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
  font-size: 24px;
  width: fit-content;
  padding-bottom: 2px;
  border-bottom: 2px solid #000;
}
.event-description-box .event-description{
  color: rgba(0, 0, 0, 0.7);
  line-height: 32px;
  font-size: 16px;
  margin: 0 0 24px 0;
}
.covid-instruction{
  margin: 48px 0;
}
.covid-instruction h4{
  border-bottom: none;
  padding-bottom: 0;
  margin-left: 12px;
  margin-bottom: 0;
  font-size: 20px;
}
.covid-instruction .safety{
  color: #000;
  margin: 12px 0;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.7);
}
.covid-instruction .safety span{
  color: #6c30ed;
  font-weight: bold;
  margin-left: 4px;
}
.limited-capacity{
  width: fit-content;
  border-radius: 30px;
  margin-top: 20px;
  padding: 4px 16px 4px 12px;
  box-shadow: #6c30ed 0px 0px 3px;
}
.limited-capacity span{
  font-size: 16px;
  font-weight: normal !important;
  color: rgba(0, 0, 0, 0.7) !important;
}
.instruction-heading{
  color: #000;
  border-bottom: none !important;
}
.venue-detail span:first-child{
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.8);
}
.venue-detail span:last-child{
  color: rgba(0, 0, 0, 0.8);
}
.get-d{
  font-size: 12px;
  font-weight: bold;
  border: 1px solid rgba(0,0,0,0.2);
}
.suggested-event-box{
  margin-top: 24px;
  margin-bottom: 48px;
}
.event-box .primary-button{
  font-size: 18px;
}
.event-box .primary-button svg{
  font-size: 20px;
  margin-right: 4px;
}
.instruction-heading{
  color: #000;
  border-bottom: none !important;
}
.suggested-events{
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
}
.finger{
  position: absolute;
  right: -26px;
  top: 6px;
  transform: rotate(180deg);
}
.event-card-detail h5{
  color: #000;
  font-weight: bold;
}
.event-card-detail ul{
  list-style: none;
  padding: 0;
  margin: 24px 0 0 0;
}
.event-card-detail ul li{
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.event-card-detail ul li span{
  color: #000;
  margin-left: 8px;
  font-size: 16px;
}
.event-card-detail ul li .price-box .price{
  margin-left: 8px;
}
.event-card-detail ul li .price-box span{
  font-size: 18px;
  font-weight: bold;
  margin-left: 2px;
  margin-bottom: 2px;
}
.event-card-detail ul li:last-child{
  margin-top: 24px;
  justify-content: space-between;
}
.event-card-root{
  width: 100%;
  border-radius: 4px;
  transition: 800ms;
  vertical-align: top;
  display: inline-block;
  margin-bottom: 24px;
  margin-right: 12px;
  border: 1px solid #000;
  box-shadow: 0 0px 30px 0 rgb(0 0 0 / 30%);
}
.event-card-root .event-card-content{
  padding: 12px;
}
.event-card-root img{
  width: 100%;
  height: 248px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.event-card-root .venue-box{
  min-height: 44px;
  display: flex;
  align-items: center;
}
.event-card-root p .time-icon{
  top: 7px;
  left: 10px;
  width: fit-content;
  position: absolute;
}
.event-card-root p .date-time{
  margin-left: 8px;
  margin-top: 3px;
}
.event-card-root .event-venue{
  margin-left: 8px;
  font-size: 15px;
}
.event-card-root p .time-icon svg{
  background-color: #fff;
}
.event-card-root div{
  width: 100%;
}
.event-card-root a{
  text-decoration: none;
  color:#fff;
  font-weight: bold;
  transition: 800ms;
  background-color: #d71ef7;
  padding: 4px 8px;
  border-radius: 4px;
}
.event-card-content .card-root{
  margin: 24px 0 12px 0;
}
.event-card-content .card-root .price{
  color: #000;
  display: flex;
  align-items: center;
}
.event-card-content .card-root .price span{
  font-weight: bold;
  font-size: 18px;
}
.event-condition-card{
  margin-top: 24px;
}
.event-condition-card h5{
  color: #000;
  font-weight: bold;
  font-size: 18px;
}
.event-condition-card ul{
  list-style-type: none;
  padding: 0;
  margin: 24px 0 0 0;
}
.event-condition-card ul li{
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.event-condition-card ul li p{
  margin: 0 0 0 12px;
}
.event-condition-card ul li p span:first-child{
  color: rgba(0, 0, 0, 0.7);
}
.event-condition-card ul li p span:last-child{
  color: rgba(0, 0, 0, 1);
}
.g-img button{
  width: 120px;
  height: 80px;
  background: none;
  border: none;
}
.g-img button img{
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0);
}
.total-box .total-item{
  display: flex;
  margin: 24px 12px;
  border-radius: 4px;
  padding: 24px 20px;
  border-left: 3px solid;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
}
.total-box .total-item h4{
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}
.total-box .total-item p{
  margin: 0;
  font-size: 16px;
  font-weight: bold;
}
.total-box .total-item svg{
  color: rgba(0, 0, 0, 0.2);
}
.total-box .total-item .total-earning svg{
  margin-top: -2px;
  color: rgba(0, 0, 0);
}
.order-panel{
  padding: 24px 20px;
  border-radius: 4px;
  border-left: 3px solid;
  margin-bottom: 24px;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
}
.panel-aria{
  display: flex;
}
.p-aria-1{
  width: 60%;
  margin-right: 48px;
}
.p-aria-2{
  width: 30%;
}
.btn-search-order{
  height: 70px;
  display: flex;
  align-items: flex-end;
}
.btn-search-order button{
  width: 60px;
  margin-left: 12px;
  height: 37px;
  border: none;
  border-radius: 4px;
  background-color: rgb(108,48,237);
}
.table-mt{
  margin-top: 36px;
}
.small-circle{
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 4px !important;
}
.ticket-content tr td:first-child{
  font-weight: bold;
  color: rgb(108,48,237);
}
.access-denied{
  display: flex;
  height: 80vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.access-denied h3{
  font-weight: bold;
  margin: 20px 0 8px;
}
.header-image-container{
  width: 800px;
  margin-top: 12px;
}
.header-image-container img{
  width: 100%;
  border-radius: 8px;
}
.header-image{
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.change-image{
  display: flex;
  margin-top: 24px;
  justify-content: center;
}
.upload-image-form{
  margin-top: 24px;
  text-align: center;
}
.image-loader{
  margin-top: 12px;
  width: 32px !important;
}
.comment-box{
  margin-top: 12px;
  margin-right: 8px;
  display: inline-block;
}
.comment-box div{
  display: flex;
  padding: 4px 8px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
}
.comment-box div p{
  margin-bottom: 0;
  font-weight: bold;
}
.comment-box div button{
  border: none;
  background: none;
  font-weight: bold;
}
.option-text{
  font-size: 12px;
  margin-left: 4px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.5);
}
.logo-button{
  border: none;
  background: none;
}
.btn-add{
  display: flex;
  padding-right: 8px;
  padding-left: 8px;
  font-weight: bold;
  align-items: center;
  justify-content: center;
}
.btn-add svg{
  margin-left: 4px;
}
.close-modal{
  top: 0;
  right: 0;
  z-index: 1;
  border: none;
  font-size: 20px;
  background: none;
  font-weight: bold;
  position: absolute;
}
.download-csv{
  border: none;
  color: #fff;
  display: flex;
  cursor: pointer;
  font-weight: bold;
  padding: 6px 12px;
  align-items: center;
  background-color: #6c30ed;
}
.select-limit{
  width: 52px;
  margin-right: 8px;
}
@media screen and (min-width: 1280px) {
  .event-card-root{
    width: 360px;
  }
}
